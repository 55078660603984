import { NavBar } from "../components/general/NavBar"
import { Footer } from "../components/general/Footer"

export default function Profile() {


  return (
    <>
      <NavBar pagename='404' />
      PROFILE Page
      <Footer />
    </>
  )
}