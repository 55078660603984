import { Container, Row, Col, Card, Button, Modal, Image } from 'react-bootstrap'
import { useState } from 'react'
// import { ArrowRightCircle } from 'react-bootstrap-icons'
// import { Link } from 'react-router-dom'
import infoImage1 from '../../assets/backgrounds/goldenCardMembership.png'
import infoImage2 from '../../assets/backgrounds/goldenCardPathways.png'
import infoImage3 from '../../assets/backgrounds/goldenCardIcebreaker.png'
import infoImage4 from '../../assets/backgrounds/goldenCardMeetingRoles.png'
import infoImage5 from '../../assets/backgrounds/goldenCardCOT.png'
import infoImage6 from '../../assets/backgrounds/goldenCardContests.png'
import infoImage7 from '../../assets/backgrounds/goldenCardAboutUs.png'
import infoImage8 from '../../assets/backgrounds/goldenCardAboutToastmasters.png'
import infoImage9 from '../../assets/backgrounds/goldenCardTimer.png'

export const Info = () => {
  const [showMembership, setShowMembership] = useState(false)
  const handleCloseMembership = () => setShowMembership(false)
  const handleShowMembership = () => setShowMembership(true)

  const [showPathways, setShowPathways] = useState(false)
  const handleClosePathways = () => setShowPathways(false)
  const handleShowPathways = () => setShowPathways(true)

  const [showIcebreaker, setShowIcebreaker] = useState(false)
  const handleCloseIcebreaker = () => setShowIcebreaker(false)
  const handleShowIcebreaker = () => setShowIcebreaker(true)

  const [showMeetingRoles, setShowMeetingRoles] = useState(false)
  const handleCloseMeetingRoles = () => setShowMeetingRoles(false)
  const handleShowMeetingRoles = () => setShowMeetingRoles(true)

  const [showTraining, setShowTraining] = useState(false)
  const handleCloseTraining = () => setShowTraining(false)
  const handleShowTraining = () => setShowTraining(true)

  const [showContests, setShowContests] = useState(false)
  const handleCloseContests = () => setShowContests(false)
  const handleShowContests = () => setShowContests(true)

  const [showAboutUs, setShowAboutUs] = useState(false)
  const handleCloseAboutUs = () => setShowAboutUs(false)
  const handleShowAboutUs = () => setShowAboutUs(true)

  const [showAboutToastmasters, setShowAboutToastmasters] = useState(false)
  const handleCloseAboutToastmasters = () => setShowAboutToastmasters(false)
  const handleShowAboutToastmasters = () => setShowAboutToastmasters(true)

  return (
    <section className='info' id="info">
      <Container>
        <Row className="text-center">
          <h2>Useful Info</h2>
          <p></p>
        </Row>
        <Row>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center" variant="dark">
              <Card.Img variant="top" src={infoImage1} alt="infoImage1" />
              <div className='info-txtx'>
                <h4>Membership</h4>
                <span>Responsibilities and benefits</span><br /><br />
                <Button onClick={handleShowMembership}>Read more</Button>

              </div>
            </Card>
            <Modal show={showMembership} onHide={handleCloseMembership} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Membership</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>Guest</h2>
                    <p>Toastmasters clubs are welcoming guests to attend Toastmasters meetings and events for free. Although the guests would be limited from having access to complete education packages, or leadership positions, we offer them a chance at every regular meeting to practice during Table Topics sessions (impromptu speeches) as well as taking some small roles during the meetings. For more details, feel free to discuss it with the Vice President for Education.</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>Membership</h2>
                    <p>Joining Toastmasters is easy and affordable. When you become a member, you'll have the support of o8ur club, district and the global organization as you work toward your goals. By becoming a member you're opening a door towards professional public speaking, towards successful leadership and towards a world of fascinating stories, great lessons and fantastic personal growth.</p>
                    <a href='https://www.toastmasters.org/membership/why-toastmasters' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>Payment details for Membership</h2>
                    <p>
                      Payments for membership are to be made via bank transfer to account 2600101541 / 2010 (FIO BANKA).
                      <br />
                      Regular payments are made every March and September of the year.
                    </p>
                    <p>
                      <span style={{ backgroundColor: "#E84D1C" }}>Important note for new members who want to join:</span> in between those regular payments, a prospective new member must contact the Treasurer and the Vice President for Membership of the club to calculate the correct amount to be paid based on the time left until next regular payment schedule.
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col xs={12} sm={4} md={4}>
                    <Image className="mx-auto" src="/img/FioQRMembership.png" width={200} />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseMembership}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center" variant="dark">
              <Card.Img variant="top" src={infoImage2} alt="infoImage2" />
              <div className='info-txtx'>
                <h4>Education and Pathways</h4>
                <span>Choose your own path</span><br /><br />
                <Button onClick={handleShowPathways}>Read more</Button>
              </div>
            </Card>
            <Modal show={showPathways} onHide={handleClosePathways} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Education & Pathways</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col lg={4} s={12}>
                    <iframe title='youtube' width={'100%'} src="https://www.youtube.com/embed/iv9cKPn0Wpo?autoplay=0&enablejsapi=1" />
                  </Col>
                  <Col>
                    <h2>Education</h2>
                    <p>Education is an important part of every Toastmaster's journey, and it is a critical aspect of all club meetings around the world. Participating in Toastmasters' education programs allows you to gain the valuable practice needed to develop your public speaking, communication, and leadership skills. The safe and supportive environment clubs provide allows you to learn at your own pace in a curriculum that has helped millions meet their personal and professional goals.</p>
                    <a href='https://www.toastmasters.org/education' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>What are Pathways</h2>
                    <p>Toastmasters Pathways learning experience, an exciting, flexible and interactive way to develop your skills and help others in your club develop theirs. Pathways helps you learn communication and leadership skills that you need to succeed.</p>
                    <a href='https://www.toastmasters.org/pathways-overview' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePathways}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage3} alt="infoImage3" />
              {/* <Card.ImgOverlay>
                                <div className='info-overlay'>
                                    <h4>Break the Ice</h4>
                                </div>
                            </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>Break the Ice</h4>
                <span>Have your first speech</span><br /><br />
                <Button onClick={handleShowIcebreaker}>Read more</Button>
              </div>
            </Card>
            <Modal show={showIcebreaker} onHide={handleCloseIcebreaker} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Break the Ice</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>Sample your first Project</h2>
                    <p>
                      Take an exciting peek at the first project on your Toastmasters journey - the Ice Breaker. See how projects are structured in Pathways and experience how clear and simple the objectives are to achieve.
                    </p>
                    <p>
                      The Ice Breaker is your first opportunity to share something about yourself and your goals with other members of your club. You may want to include the following information in your speech to help club members get to know you:
                    </p>
                    <ul>
                      <li>
                        The reason you joined Toastmasters (if a person or incident influenced you to join, share that story)
                      </li>
                      <li>
                        Your communication and leadership goals
                      </li>
                    </ul>
                    <a href='https://www.toastmasters.org/websiteApps/Pathways/IceBreaker/tm100101/index.html' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                  <Col lg={4} s={12}>
                    <iframe title='youtube' width={'100%'} src="https://www.youtube.com/embed/nAgeDAjpvqE?autoplay=0&enablejsapi=1" />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseIcebreaker}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage4} alt="infoImage4" />
              {/* <Card.ImgOverlay>
                                <div className='info-overlay'>
                                    <h4>Club Meeting Roles</h4>
                                </div>
                            </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>Club Meeting Roles</h4>
                <span>Understand the meeting structure</span><br /><br />
                <Button onClick={handleShowMeetingRoles}>Read more</Button>
              </div>
            </Card>
            <Modal show={showMeetingRoles} onHide={handleCloseMeetingRoles} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Club Meeting Roles</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>Club Meeting Roles</h2>
                    <p>The success of a club meeting depends on the participants. At each meeting, there are many roles to fill and they play an important part in making the club experience enjoyable.</p>
                    <a href='https://www.toastmasters.org/membership/club-meeting-roles' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseMeetingRoles}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage5} alt="infoImage5" />
              {/* <Card.ImgOverlay>
                                <div className='info-overlay'>
                                    <h4>Club Officers Training</h4>
                                </div>
                            </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>Club Officers Training</h4>
                <span>Learn about leadership</span><br /><br />
                <Button onClick={handleShowTraining}>Read more</Button>
              </div>
            </Card>
            <Modal show={showTraining} onHide={handleCloseTraining} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Club Officer Training</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>Club Officer Training</h2>
                    <p>Club officers are best served by consistent, professional, and engaging training that supports the brand, enhances member experience, and helps them grow as leaders. The materials provided by Toastmasters International are the standard of quality and consistency that should make up the core of District-sponsored Club Officer Training.</p>
                    <h2>New Session Materials</h2>
                    <p>Training should be time well spent for everyone. Make the most of your training time with ready-to-use training sessions that follows a well-structured format and delivers engaging training to club officers. Logically structured guides help facilitators lead sessions that promote vital learning points to club officers and save hours in research and development.</p>
                    <a href='https://www.toastmasters.org/leadership-central/district-leader-tools/training/club-officer-training-materials' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseTraining}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage6} alt="infoImage6" />
              {/* <Card.ImgOverlay>
                                <div className='info-overlay'>
                                    <h4>Contests</h4>
                                </div>
                            </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>Contests</h4>
                <span>Practice what you have learned</span><br /><br />
                <Button onClick={handleShowContests}>Read more</Button>
              </div>
            </Card>
            <Modal show={showContests} onHide={handleCloseContests} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Contests</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>Contests</h2>
                    <p>
                      Speech contests are a Toastmasters tradition. Each year, thousands of Toastmasters compete in the Humorous, Evaluation, Tall Tales, Table Topics and International speech contests. Competition begins with club contests, and winners continue competing through the Area, Division and District levels. Winners of the District level International Speech Contest proceed to the region quarterfinal level. Following region quarterfinals, winners advance to the semifinals for a chance to take part in the World Championship of Public Speaking®.
                      <br />
                      In order to participate to these contests, there is a set of rules.
                    </p>
                    <p>
                      To be eligible to compete in any official Toastmasters speech contest, a member must:
                    </p>
                    <ul>
                      <li>
                        be a paid member of a club in the Area, Division, and District in which they are competing.
                      </li>
                      <li>
                        have earned certificates of completion in Levels 1 and 2 in any path in the Toastmasters Pathways learning experience or earned a Distinguished Toastmaster award.
                      </li>
                      <li>
                        not be part of District officers whose terms expire June 30 or candidates for elected positions for the term beginning in the upcoming July 1.
                      </li>
                    </ul>
                    <p>
                      To be a chief judge, voting judge or tiebreaking judge at a Toastmasters speech contest you must meet all eligibility requirements identified below.
                    </p>
                    <ol>
                      <li>
                        At a club contest, be a paid member.
                      </li>
                      <li>
                        At an Area, Division or District Contest:
                        <ul>
                          <li>
                            be a paid member for a minimum of six (6) months
                          </li>
                          <li>
                            have completed a minimum of six (6) speech projects or earned certificates of completion in Levels 1 and 2 of any path in the Toastmasters Pathways learning experience.
                          </li>
                        </ul>
                      </li>
                    </ol>
                    <p>
                      All other contest officials and the Evaluation Contest test speaker must be paid members.
                    </p>
                    <a href='https://www.toastmasters.org/leadership-central/speech-contests' target='_blank' rel="noreferrer" className="services-link">Learn More</a>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseContests}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage7} alt="infoImage7" />
              {/* <Card.ImgOverlay>
                                <div className='info-overlay'>
                                    <h4>About us</h4>
                                </div>
                            </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>About us</h4>
                <span>History & Details</span><br /><br />
                <Button onClick={handleShowAboutUs}>Read more</Button>
              </div>
            </Card>
            <Modal show={showAboutUs} onHide={handleCloseAboutUs} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>About Us</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>About Us</h2>
                    <p>
                      Brno Toastmasters was by a group of enthusiasts with a passion for communication and personal growth. Since the beginning, the club's meetings offered a supportive atmosphere and valuable feedback to people who wanted to overcome the fear of speaking in public or looked to embark on a new leadership challenge.
                      <br />
                      Our mission is to develop the new leaders of tomorrow.
                    </p>
                    <p>
                      In 2008, the club IBM Brno Toastmasters was established, as a company club under Brno branch of IBM.
                      <br />
                      In April 2009, the club was renamed to Brno Toastmasters and opened membership to public.
                    </p>
                    <br /><br />
                    <h4>Brno Toastmasters</h4>
                    <p>
                      Club Number 1125152
                      <br />
                      Area 1
                      <br />
                      Division C
                      <br />
                      District 110
                    </p>
                    <p>
                      Brno Toastmasters z.s. is a non-profit organization registered under the IČO 22855599
                    </p>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAboutUs}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage8} alt="infoImage8" />
              {/* <Card.ImgOverlay>
                                <div className='info-overlay'>
                                    <h4>About Toastmasters</h4>
                                </div>
                            </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>About Toastmasters</h4>
                <span>Toastmasters International Organization</span><br /><br />
                <Button onClick={handleShowAboutToastmasters}>Read More</Button>
              </div>
            </Card>
            <Modal show={showAboutToastmasters} onHide={handleCloseAboutToastmasters} data-bs-theme="dark" centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>About Toastmasters International Organization</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <h2>About Toasmasters International Organization</h2>
                    <p>
                      Toastmasters International is a nonprofit educational organization that builds confidence and teaches public speaking skills through a worldwide network of clubs that meet online and in person. In a supportive community or corporate environment, members prepare and deliver speeches, respond to impromptu questions, and give and receive constructive feedback. It is through this regular practice that members are empowered to meet personal and professional communication goals. Founded in 1924, the organization is headquartered in Englewood, Colorado with approximately 270,000 members in more than 14,200 clubs in 148 countries.
                    </p>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAboutToastmasters}>Close</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <Card className="info-cardbx text-center">
              <Card.Img variant="top" src={infoImage9} alt="infoImage9" />
              {/* <Card.ImgOverlay>
                    <div className='info-overlay'>
                        <h4>About Toastmasters</h4>
                    </div>
                </Card.ImgOverlay> */}
              <div className='info-txtx'>
                <h4>WEB TM Timer</h4>
                <span>Toastmasters Timer Web tool</span><br /><br />
                <a href="https://tm-timer.ddns.net/" target='_blank' rel="noreferrer" variant='button'>Open Web App</a>

              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}