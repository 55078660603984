import { useState } from 'react'
import { useSelector } from "react-redux"
import { Card, Row, Col, Button, Dropdown } from 'react-bootstrap'
import AgendaItemEditModal from './crudModals/AgendaItemEditModal'
import AgendaItemDeleteModal from './crudModals/AgendaItemDeleteModal'

export default function AgendaItemCard({ agendaItem, getAgenda }) {

  const item = agendaItem
  const { user: authUser } = useSelector(x => x.auth)
  const [isOpened, setIsOpened] = useState(false)
  const handleIsOpened = () => {
    setIsOpened(!isOpened)
  }

  return (
    <section className='agendaItemCard'>
      <Row>
        <Col>
          <Card className='my-2 semi-transparent text-white bg-dark'>
            <Card.Header>
              <Row>
                <Col xs={10} lg={11} onClick={handleIsOpened}>
                  {isOpened ?
                    <>
                      <h1 className='large'><span>{item.startTime}</span> - {item.event}</h1>
                      <h2 className='large'>{item.presenter} <span>({item.role})</span></h2>
                    </>
                    :
                    <>
                      <h4><span>{item.startTime}</span> - {item.event}</h4>
                      <h5>{item.presenter} <span>({item.role})</span></h5>
                    </>
                  }
                </Col>
                <Col xs={2} lg={1}>
                  {authUser &&
                    <Dropdown drop='start' data-bs-theme="dark" className='float-end'>
                      <Dropdown.Toggle variant="dark">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" className="svg-inline--fa fa-ellipsis-vertical " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="6" height="16"><path fill="currentColor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path></svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <AgendaItemEditModal agendaItem={item} getAgenda={getAgenda} />
                        <Dropdown.Divider />
                        <AgendaItemDeleteModal agendaItem={item} getAgenda={getAgenda} />
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>
              </Row>

            </Card.Header>
            {isOpened &&
              <Card.Body className='text-center'>
                <h3>{item.eventDescription}</h3>
              </Card.Body>
            }
            <Card.Footer>
              {
                isOpened ?
                  <>
                    {(item.timeRed) && <Col className='large'><small>
                      {(item.timeGreen !== "") && <Button variant='success' className='timeButton'>{item.timeGreen}</Button>}
                      {(item.timeAmber !== "") && <Button variant='warning' className='text-black timeButton'>{item.timeAmber}</Button>}
                      {(item.timeRed !== "") && <Button variant='danger' className='timeButton'>{item.timeRed}</Button>}
                    </small></Col>}
                  </>
                  :
                  <>
                    {(item.timeRed) && <Col><small>
                      {(item.timeGreen !== "") && <Button variant='success' className='timeButton'>{item.timeGreen}</Button>}
                      {(item.timeAmber !== "") && <Button variant='warning' className='text-black timeButton'>{item.timeAmber}</Button>}
                      {(item.timeRed !== "") && <Button variant='danger' className='timeButton'>{item.timeRed}</Button>}
                    </small></Col>}
                  </>
              }

            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </section>
  )
}

