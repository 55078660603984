import { Button, Dropdown, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export default function AgendaItemDeleteModal({ agendaItem, getAgenda }) {

    const { user: authUser } = useSelector(x => x.auth)

    const url = process.env.REACT_APP_URL
    const [saveLoading, setSaveLoading] = useState(false)
    const [showDeleteAgendaItemModal, setShowDeleteAgendaItemModal] = useState(false)
    const handleCloseDeleteAgendaItemModal = () => setShowDeleteAgendaItemModal(false)
    const handleShowDeleteAgendaItemModal = () => setShowDeleteAgendaItemModal(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSaveLoading(true)
        fetch(url + '/agendaItem/' + agendaItem._id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authUser.token
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('(' + res.status + ') We could not delete the Agenda Item ' + agendaItem.event)
                }
                return res.json()
            })
            .then(resData => {
                toast.success('Your Agenda Item ' + agendaItem.event + ' has been deleted', { position: 'bottom-left' })
                setSaveLoading(false)
                getAgenda()
                handleCloseDeleteAgendaItemModal()
            })
            .catch(err => {
                toast.error('' + err, { position: 'bottom-left' })
                setSaveLoading(false)
            })
    }
    return (
        <>
            <Dropdown.Item onClick={handleShowDeleteAgendaItemModal} style={{ color: '#ff848f' }}>Delete Agenda Item</Dropdown.Item >
            {/* <Button onClick={handleShowDeleteAgendaItemModal} variant='danger' className='float-end'>
                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="14px" width="14px">
                    <g>
                        <g>
                            <path d="M331.636,69.818V0H180.364v69.818h-128v128h34.909V512h337.455V197.818h34.909v-128H331.636z M215.273,34.909h81.455
			v34.909h-81.455V34.909z M389.818,477.091H122.182V197.818h267.636V477.091z M424.727,162.909H87.273v-58.182h337.455V162.909z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect x="168.727" y="256" width="34.909" height="162.909" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect x="308.364" y="256" width="34.909" height="162.909" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <rect x="238.545" y="256" width="34.909" height="162.909" />
                        </g>
                    </g>
                </svg>
            </Button> */}
            < Modal centered show={showDeleteAgendaItemModal} onHide={handleCloseDeleteAgendaItemModal} className='text-center' data-bs-theme={'dark'}>
                <Modal.Header closeButton>
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Delete Agenda Item: </h2><h4>{agendaItem.event}</h4>
                    Are you sure you want to delete this agenda item?
                </Modal.Body>
                <Modal.Footer>
                    {saveLoading
                        ?
                        'Deleting Agenda Item ... '
                        :
                        <>
                            <Button variant='danger' type='submit' onClick={handleSubmit}>
                                Delete Agenda Item
                            </Button>
                            <Button variant='dark' onClick={handleCloseDeleteAgendaItemModal}>
                                Cancel
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal >
        </>
    )
}