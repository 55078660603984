import { Route, Routes } from "react-router-dom"

import Home from "../pages/Home";
import Meetings from '../pages/Meetings'
import Meeting from '../pages/Meeting'
import NotFoundPage from "../pages/404"
import Profile from "../pages/Profile";

const AppRouter = () => (
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/profile/' element={<Profile />} />
        <Route path='/meetings/' element={<Meetings />} />
        <Route path='/meeting/:meetingId' element={<Meeting />} />
        <Route path='*' element={<NotFoundPage />} />
    </Routes>
)

export default AppRouter 