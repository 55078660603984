import { Container, Row, Col } from "react-bootstrap";

import navIcon1 from "../../assets/img/nav-icon1.svg";
import navIcon2 from "../../assets/img/nav-icon2.svg";
import youTube from '../../assets/svg/brand-logos/icons8-youtube-logo-100.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={6}>
            <div inline='true' className="m-2">
              <p>Public Speaking Club</p>
              <h4>BRNO TOASTMASTERS</h4>
            </div>
          </Col>
          <Col xs={12} md={6} xl={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/groups/4129031/" target='_blank' rel="noreferrer"><img src={navIcon1} alt="Linkedin Icon Footer" /></a>
              <a href="https://www.facebook.com/BrnoToastmasters/" target='_blank' rel="noreferrer"><img src={navIcon2} alt="Facebook Icon footer" /></a>
              <a href="https://youtube.com/playlist?list=PL50Fjntx45Ga6ANLDDD6FNj7Benaebndj&si=BTcYMsYyu7GltipP" target='_blank' rel="noreferrer"><img src={youTube} alt="YouTube Icon footer" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved. Developed and maintained by <a href="http://bit4u.ddns.net" target="_blank" rel="noreferrer">bit4u</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}