import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import Dropdown from 'react-bootstrap/Dropdown'
import { useState } from 'react'
import { useSelector } from 'react-redux'

// import './FormModal.css'

export default function MeetingDeleteModal({ meeting, getMeeting }) {
    const url = process.env.REACT_APP_URL

    const { user: authUser } = useSelector(x => x.auth)

    const [saveLoading, setSaveLoading] = useState(false)
    const [showDeleteMeetingModal, setShowDeleteMeetingModal] = useState(false)
    const handleCloseDeleteMeetingModal = () => setShowDeleteMeetingModal(false)
    const handleShowDeleteMeetingModal = () => setShowDeleteMeetingModal(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSaveLoading(true)
        fetch(url + '/meeting/' + meeting._id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authUser.token
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('(' + res.status + ') We could not delete the meeting #' + meeting.meetingNumber)
                }
                return res.json()
            })
            .then(resData => {
                console.log('DELETE: response JSON: ' + resData)
                toast.success('Your meeting #' + meeting.meetingNumber + ' has been deleted', { position: 'bottom-left' })
                setSaveLoading(false)
                getMeeting()
                handleCloseDeleteMeetingModal()
            })
            .catch(err => {
                toast.error('' + err, { position: 'bottom-left' })
                setSaveLoading(false)
            })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShowDeleteMeetingModal}>Delete Meeting</Dropdown.Item>
            <Modal centered show={showDeleteMeetingModal} onHide={handleCloseDeleteMeetingModal} className='text-center' data-bs-theme={'dark'}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete Meeting: <br /> {meeting.meetingTheme}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this meeting?
                </Modal.Body>
                <Modal.Footer>
                    {saveLoading
                        ?
                        'Deleting meeting ... '
                        :
                        <>
                            <Button variant='danger' type='submit' onClick={handleSubmit}>
                                Delete Meeting
                            </Button>
                            <Button variant='dark' onClick={handleCloseDeleteMeetingModal}>
                                Cancel
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}